import React from "react";
import { Routes, Route } from "react-router-dom";
import Home from "../Views/home";
import ContactPage from "../Views/ContactPage";

function Router() {
  return (
    <React.Fragment>
      <Routes>
        <Route path="/" element={<Home />}></Route>
        <Route path="/contact" element={<ContactPage />}></Route>
      </Routes>
    </React.Fragment>
  );
}
export default Router;
