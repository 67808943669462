import React from "react";
import cruxito from "../images/Cruxito_Logo.svg";

function Header() {
  return (
    <header className="header">
      <div className="container">
        <div className="header_cnt">
          <a href="/" className="logo">
            <img src={cruxito} alt="cruxito" />
          </a>
          <nav>
            <ul>
              <li>
                <a href="https://cruxito.tech/blog" target="_blank">
                  Blog
                </a>
              </li>
              <li>
                <a className="contact" href="/contact">
                  Contact Us
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </header>
  );
}
export default Header;
