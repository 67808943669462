import React from "react";
import cruxito from "../images/Cruxito_Logo.svg";

function Footer() {
  return (
    <footer>
      <div className="container">
        <div className="footer-head">
          <div className="navigations">
            <a href="https://medium.com/@cruxitotechsolutions" target="_blank">
              Blog
            </a>
            <span>
              <img src={require("../images/aws.png")} alt="aws" />
            </span>
          </div>
          <a href="/" className="logo">
            <img src={cruxito} alt="cruxito" />
          </a>

          <div className="email_sec">
            <label>Get our 10 Steps to Success Guide</label>
            <div className="footer_input">
              <input type="text" placeholder="Enter Your Email id" />
              <button>Get the Guide</button>
            </div>
          </div>
        </div>

        <div className="footer_bottom">
          <ul className="social_networks">
            <li>
              <a href="#">
                <img src={require("../images/twitter.png")} alt="twitter" />
              </a>
            </li>
            <li>
              <a href="#">
                <img src={require("../images/facebook.png")} alt="facebook" />
              </a>
            </li>
            <li>
              <a href="#">
                <img src={require("../images/instagram.png")} alt="instagram" />
              </a>
            </li>
          </ul>
          <ul className="social_networks wauto">
            <li>
              <a
                href="https://cruxito.tech/blog/privacy-policy/"
                target="_blank"
              >
                Privacy Policy
              </a>
            </li>
            <li>
              <a href="https://cruxito.tech/blog/terms-of-use/">Terms of Use</a>
            </li>
            <li>
              <a href="https://cruxito.tech/blog/unsubscribe-form/">
                Unsubsribe
              </a>
            </li>
          </ul>
          <label>© 2023 Cruxito, LLC</label>
        </div>
      </div>
    </footer>
  );
}
export default Footer;
