import React, { useState } from "react";
import Location from "../images/location.svg";
import Phone from "../images/phone.svg";
import Email from "../images/email.svg";

function ContactPage() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [dept, setDept] = useState("");
  const [info, setInfo] = useState("");
  const [phone, setPhone] = useState("");
  const [isChecked, setIsChecked] = useState(false);

  const submitToAPI = (e) => {
    e.preventDefault();

    const URL =
      "https://2s58m6a1n2.execute-api.us-east-1.amazonaws.com/default/wisdomiteSendEmailPy";

    if (!name || name.length < 2) {
      alert("Name cannot be empty and should be at least 2 characters long");
      return;
    }

    if (!email) {
      alert("Please enter your email id");
      return;
    }
    if (!dept) {
      alert("Please enter subject");
      return;
    }
    if (!info) {
      alert("Please enter message what you need");
      return;
    }

    const reEmail = /^([\w-\.]+@([\w-]+\.)+[\w-]{2,6})?$/;
    if (!reEmail.test(email)) {
      alert("Please enter a valid email address");
      return;
    }

    const data = {
      name,
      email,
      dept: dept || "General Information Request",
      info,
    };

    fetch(URL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then(() => {
        alert("Successful");
        setName("");
        setEmail("");
        setDept("");
        setInfo("");
        setPhone("");
        setIsChecked(false);
      })
      .catch(() => {
        alert("Successfully Submitted");
        setName("");
        setEmail("");
        setDept("");
        setInfo("");
        setPhone("");
        setIsChecked(false);
      });
  };

  return (
    <div className="contact-us_section contactpage">
      <div className="container">
        <div className="contact_section">
          <h2>CONTACT US</h2>
          <div className="content">
            <p className="description">
              Contact us to get expert guidance, strategy and execution
              services. <br />
              We are now offering limited free consultations and mentorship on
              demand basis
            </p>
          </div>
        </div>
        <div className="contact_form">
          <div className="details">
            <ul>
              <li>
                <span>
                  <img src={Location} alt="Location" />
                </span>
                <div className="list-item">
                  <label>Address</label>
                  <a>Lynnwood, WA 98087</a>
                </div>
              </li>
              <li>
                <span>
                  <img src={Phone} alt="Phone" />
                </span>
                <div className="list-item">
                  <label>Phone</label>
                  <a>+1 425-624-9820</a>
                </div>
              </li>
              <li>
                <span>
                  <img src={Email} alt="Email" />
                </span>
                <div className="list-item">
                  <label>E-mail</label>
                  <a href="mailto:admin@cruxito.tech">admin@cruxito.tech</a>
                </div>
              </li>
            </ul>
          </div>
          <div className="form_section">
            <h2>SEND MESSAGE</h2>
            <form onSubmit={submitToAPI}>
              <ul>
                <li className="twocol">
                  <input
                    type="text"
                    placeholder="Name"
                    id="name-input"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                  <input
                    type="email"
                    placeholder="Email"
                    id="email-input"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </li>
                <li className="twocol">
                  <input
                    type="text"
                    placeholder="Subject"
                    value={dept}
                    onChange={(e) => setDept(e.target.value)}
                  />
                  <input
                    placeholder="Phone No"
                    type="number"
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                  />
                  {/* <select name="" id="" placeholder="Select Department">
                                    <option value="">Select Department</option>
                                </select> */}
                </li>
                {/* <li>
                            <input placeholder="Phone No" type="number" />
                            </li> */}
                <li>
                  <textarea
                    name=""
                    id=""
                    cols="30"
                    rows="10"
                    placeholder="Message"
                    value={info}
                    onChange={(e) => setInfo(e.target.value)}
                  ></textarea>
                </li>
                <li>
                  <div className="terms_check">
                    <input
                      type="checkbox"
                      id="phone"
                      name="vehicle1"
                      value="Bike"
                      checked={isChecked}
                      onChange={(e) => setIsChecked(e.target.checked)}
                    />
                    <label for="phone">
                      By Providing your phone number, you agree to receive a
                      text message from Cruxito. Message and Data rates may
                      apply. Message frequency varies.
                    </label>
                  </div>
                </li>
              </ul>
              <button type="submit">Submit</button>
            </form>
            <div className="disclaimer">
              <p>
                <strong></strong>To stop receiving messages, reply '
                <strong>STOP</strong>' at any time. <br />
                For more information, reply '<strong>HELP</strong>".
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="map">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d42829.00234625085!2d-122.30425010005067!3d47.86259161657081!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x549004398ccf05e9%3A0x8aa6b2b9b134edc4!2sLynnwood%2C%20WA%2098087%2C%20USA!5e0!3m2!1sen!2sin!4v1731400939591!5m2!1sen!2sin"
          width="600"
          height="450"
          allowfullscreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>
    </div>
  );
}
export default ContactPage;
