import React from "react";
// import Container from 'react-bootstrap/Container';
// import Row from 'react-bootstrap/Row';
// import Col from 'react-bootstrap/Col';
import { Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import Contact from "./Contact";

function Home() {
  return (
    <React.Fragment>
      <div className="home-banner">
        <div className="container">
          <div className="banner-cnt">
            <div className="banner-text">
              <h2>
                Accelerating the Growth of Startups with the Collaboration of
                Tech-Industry Experts
              </h2>
              <p>
                Our goal is to help you grow faster and become a first-mover in
                your market. We’re not satisfied until YOU succeed.
              </p>
              <a href="/contact" className="cta">
                Contact Us
              </a>
            </div>
            <figure>
              <img
                src={require("../images/Cruxito-Section-1.png")}
                alt="Cruxito"
              />
            </figure>
          </div>
        </div>
      </div>

      <div className="Bundle_section">
        <div className="container">
          <div className="Bundle_container">
            <div className="Bundle_text">
              <h2>What we do segments</h2>
              <h3>Everything you need and none of the fluff:</h3>
              <ul>
                <li>CTO/Tech Lead Consultant</li>
                <li>Onshore & OffShore development</li>
                <li>Tech Mentorship</li>
                <li>Product Roadmap & Strategy</li>
                <li>Project Management</li>
                <li>30% Reduced Average Cost</li>
              </ul>
            </div>
            <div className="Bundle_banner">
              <figure>
                <img
                  src={require("../images/Cruxito-Build-Together-Graphic.png")}
                  alt="Startup Bundle"
                />
              </figure>
              <a href="" className="action">
                Let’s Get Started!
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="Bundle_content">
        <div className="container">
          <div className="banner_section">
            <div className="banner_content">
              <h2>Why Work with Cruxito:</h2>
              <p>
                We provide peace of mind that your project is coming together
                smoothly. With product and technical roadmap consulting,
                efficient execution and mentoring you can rest assured that your
                time, effort and money are being used wisely.
              </p>
            </div>
            <figure>
              <img
                src={require("../images/Cruxito-Section-3-Work-With.png")}
                alt="Cruxito"
              />
            </figure>
          </div>
          <ul className="skills_section">
            <li>
              <div className="content">
                <h4>EXPERIENCE</h4>
                <p>
                  We leverage our varied and deep technical expertise to provide
                  tailored solutions that meet the unique needs of each client.
                </p>
              </div>
            </li>
            <li>
              <div className="content">
                <h4>EXECUTION</h4>
                <p>
                  We build with agile methodology while maintaining a focus on
                  the end goal, with tech strategy that ensures the final
                  product exceeds expectations.
                </p>
              </div>
            </li>
            <li>
              <div className="content">
                <h4>EFFICIENCY</h4>
                <p>
                  We hand select great teams of both onshore talent and offshore
                  talent giving you the advantage of cost savings and increased
                  efficiency.
                </p>
              </div>
            </li>
            <li>
              <div className="content">
                <h4>EMPOWERMENT</h4>
                <p>
                  We mentor underrepresented & minority founders to provide
                  guidance, resources, and support to help them reach their full
                  potential.
                </p>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div className="story_section">
        <div className="container">
          <figure>
            <img
              src={require("../images/Cruxito-Secrion-4-Story.png")}
              alt="Our Story"
            />
          </figure>
          <div className="banner_content">
            <h3>Our Story</h3>
            <p>
              We started Cruxito because we’ve been there before as non-tech
              founders with a great project. Struggling to find the right
              partners, consultants, and freelancers came at a cost both
              financially and strategically. At Cruxito, we work with talent
              from the top tech companies to provide expert builders who work
              closely with founders and CTOs to deliver high-quality work that
              meets their needs. Our goal is to help you achieve success,
              regardless of your level of technical expertise.
            </p>
          </div>
        </div>
      </div>
      <div className="who_section">
        <div className="container">
          <h2>Who We Are</h2>
          <p>
            We’re a passionate group of tech professionals from companies such
            as Google, Amazon, Microsoft and top tier tech companies who share
            the same passion for helping startups succeed. We take pride in
            nurturing top talent and preparing them to build amazing solutions.
            We collaborate to help you find exceptional talent and build great
            teams faster.
          </p>
        </div>
      </div>
      <div className="experiences_section">
        <div className="experiences_container">
          <h2>Experience From:</h2>
          <ul className="companies">
            <li>Google</li>
            <li>Amazon</li>
            <li>Microsoft</li>
            <li>Salesforce</li>
            <li>Nvidia</li>
          </ul>
          <ul className="experiences_list">
            <li>
              <div className="content">
                <span>5</span>
                <label>Different Industries</label>
              </div>
            </li>
            <li>
              <div className="content">
                <span>50</span>
                <label>Years of Combined Experience</label>
              </div>
            </li>
            <li>
              <div className="content">
                <span>15</span>
                <label>Clients Served and Counting</label>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div className="guidence_section">
        <div className="container">
          <h2>
            10 things to do for <br />a Successful product
            <br /> development
          </h2>
          <p>Get our free guide on getting product development right</p>
          <div className="emails">
            <input type="email" placeholder="Enter Your Email id" />
            <button>Get the Guide</button>
          </div>
          <label>* We’ll email you our guide</label>
        </div>
      </div>
      <div className="development_section">
        <div className="container">
          <div className="what_we_do">
            <div className="content"></div>
          </div>
          <div className="services_list">
            <ul>
              <li>
                <a>Product Development</a>
                <div className="sevices_info">
                  <div className="sevices_cnt">
                    <h3>What We Do</h3>
                    <h4>Product Development</h4>
                    <p>
                      We work backwards from the customer needs, strategize the
                      feature development based on business and market
                      requirements.
                    </p>
                  </div>
                </div>
              </li>
              <li>
                <a>Generative AI</a>
                <div className="sevices_info">
                  <div className="sevices_cnt">
                    <h3>What We Do</h3>
                    <h4>Generative AI</h4>
                    <p>
                      With rapidly evolving landscaping, we help clients
                      leverage GenAI by natively integrating it with the product
                      and in their business model.
                    </p>
                  </div>
                </div>
              </li>
              <li>
                <a>Cloud Native Development</a>
                <div className="sevices_info">
                  <div className="sevices_cnt">
                    <h3>What We Do</h3>
                    <h4>Cloud Native Development</h4>
                    <p>
                      We have proven expertise in multi-cloud environments and
                      build advanced cloud based solutions
                    </p>
                  </div>
                </div>
              </li>
              <li>
                <a>ML, AI & DataScience</a>
                <div className="sevices_info">
                  <div className="sevices_cnt">
                    <h3>What We Do</h3>
                    <h4>ML, AI, & DataScience</h4>
                    <p>
                      We help clients serve their customers with advanced ML &
                      AI, which in turn helps them to monetize the data in a
                      trusted and safe manner!
                    </p>
                  </div>
                </div>
              </li>
              <li>
                <a>Innovation & Experimentation</a>
                <div className="sevices_info">
                  <div className="sevices_cnt">
                    <h3>What We Do</h3>
                    <h4>Innovation & Experimentation</h4>
                    <p>
                      We put heavy emphasis on Innovation and experimentation to
                      help clients succeed! We strongly believe it’s the only
                      way businesses can sustain longer!
                    </p>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="our_work_style">
        <div className="container">
          <h2>How We Work</h2>
          <ul className="our_features">
            <li>
              <div className="content">
                <span>
                  <img
                    src={require("../images/Cruxito-Section-9-How-We-Work-1.png")}
                    alt="EXPLORE & UNDERSTAND"
                  />
                </span>
                <div className="description">
                  <h4>EXPLORE & UNDERSTAND</h4>
                  <p>
                    We explore your product, mission, and vision to understand
                    your requirements and goals.
                  </p>
                </div>
              </div>
            </li>
            <li>
              <div className="content">
                <span>
                  <img
                    src={require("../images/Cruxito-Section-9-How-We-Work-2.png")}
                    alt="ROAD MAP"
                  />
                </span>
                <div className="description">
                  <h4>DESIGN A ROAD MAP</h4>
                  <p>
                    We research, lock-in on requirements, design a solution, and
                    create a roadmap that aligns with your end goals.
                  </p>
                </div>
              </div>
            </li>
            <li>
              <div className="content">
                <span>
                  <img
                    src={require("../images/Cruxito-Section-9-How-We-Work-3.png")}
                    alt="DEVELOP THE SOLUTION"
                  />
                </span>
                <div className="description">
                  <h4>DEVELOP THE SOLUTION</h4>
                  <p>
                    We develop the solution with the best software engineering
                    standards and work with you to ensure requirements are met.
                  </p>
                </div>
              </div>
            </li>
            <li>
              <div className="content">
                <span>
                  <img
                    src={require("../images/Cruxito-Section-9-How-We-Work-4.png")}
                    alt="DELIVER RESULTS"
                  />
                </span>
                <div className="description">
                  <h4>DELIVER RESULTS</h4>
                  <p>
                    We implement, demo, and test, documenting our work to
                    deliver results.
                  </p>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div className="success_stories">
        <div className="container">
          <h2>Stories of Success</h2>
          <span className="line"></span>
          <div className="swiper_cnt">
            <Swiper
              autoHeight={true}
              modules={[Navigation]}
              spaceBetween={0}
              slidesPerView={1}
              navigation={{ clickable: true }}
              onSlideChange={() => console.log("slide change")}
              onSwiper={(swiper) => console.log(swiper)}
            >
              <SwiperSlide>
                <div className="banner_content">
                  <figure className="color-box">
                    <img
                      src={require("../images/Jovan.png")}
                      alt="Client-image"
                    />
                  </figure>
                  <div className="content">
                    <p>
                      I can confidently say that this team is exceptional in
                      every possible way. Their outstanding teamwork,
                      organizational skills, and unmatched work ethic make them
                      an invaluable asset to any project. If you are looking for
                      a team that will exceed your expectations and deliver
                      outstanding results, look no further. I wholeheartedly
                      recommend working with them.
                    </p>
                    <label className="labels">
                      Jovan Silva
                      <span>Cofounder, Matchplicity </span>
                      {/* <span>@LinkedIn Link</span>   */}
                    </label>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="banner_content">
                  <figure className="color-box">
                    <img
                      src={require("../images/Percee.png")}
                      alt="Client-image"
                    />
                  </figure>
                  <div className="content">
                    <p>
                      Cruxito has been a fantastic resource for accelerating the
                      velocity of our engineering team. As a small startup, our
                      capacity was limited and since we were interfacing with
                      students and educators our UI needed to be captivating yet
                      effective. They not only provided us with a talented
                      front-end engineer to help build out user facing tools but
                      also helped us architect a data pipeline solution in order
                      to provide scalable, event-driven learning tracking for
                      school admins. Moreover, they created real impact for both
                      our team and the communities we served.
                    </p>
                    <label className="labels">
                      Percee Goings
                      <span>Head of Engineering,Local Civics</span>
                      {/* <span>@LinkedIn Link</span>  */}
                    </label>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="banner_content">
                  <figure className="color-box">
                    <img
                      src={require("../images/Lauren.png")}
                      alt="Client-image"
                    />
                  </figure>
                  <div className="content">
                    <p>
                      Cruxito stepped in to provide engineering and development
                      support to Matchplicty at a very critical time in our
                      business’ growth trajectory. Their leadership team is
                      extremely talented, organized, efficient and flexible,
                      which as a small business was essential to our success.
                      Cruxito elevated our u/x and brought our technology
                      current. Their ability to find solutions for us made a
                      world of difference!{" "}
                    </p>
                    <label className="labels">
                      Lauren Burke Silva
                      <span>Co-Founder, CEO,Matchplicity</span>
                      {/* <span>@LinkedIn Link</span>  */}
                    </label>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="banner_content">
                  <figure className="color-box">
                    <img
                      src={require("../images/Gonzalo.png")}
                      alt="Client-image"
                    />
                  </figure>
                  <div className="content">
                    <p>
                      The Cruxito team delivered a very complex migration to
                      AWS, which would have taken us months of work. Their deep
                      knowledge and availability were key to this project and we
                      are very happy with the result, as our infrastructure is
                      now more scalable and cost efficient.
                    </p>
                    <label className="labels">
                      Gonzalo Castro Peña
                      <span>Co-founder & CTO , Kigui</span>
                      {/* <span>@LinkedIn Link</span> */}
                    </label>
                  </div>
                </div>
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
      </div>
      <div className="experiences_section our_partners">
        <div className="experiences_container">
          <h2>Our Partners</h2>
          <ul className="companies">
            <li className="brandbar">
              <img src={require("../images/brandbar.png")} alt="brandbar" />
            </li>
            <li>
              <img src={require("../images/aws.png")} alt="aws" />
            </li>
          </ul>
        </div>
      </div>
      <Contact></Contact>
    </React.Fragment>
  );
}
export default Home;
